import { FC } from 'react'
import Typography from '../../../components/Typography/Typography'
import { routes } from '../../../router'
import { HealthSystemsProps } from './types'
import './styles.scss'

export const HealthSystems: FC<HealthSystemsProps> = ({ healthSystems }) => {
  const onSelectHs = (id: string) =>
    window.open(`${routes.myCommunity}${routes.healthSystem}/${id}/`, '_blank')

  return (
    <div className="health-systems-list">
      {!!healthSystems.length ? (
        <div className="health-systems-list__list">
          {healthSystems.map((i) => (
            <div
              key={i.uuid}
              onClick={() => onSelectHs(i.uuid)}
              className="health-systems-list__list-item"
            >
              <Typography.Body1>{i.name}</Typography.Body1>
            </div>
          ))}
        </div>
      ) : (
        <Typography.Headline6 className="row justify-center">
          No Health Systems found
        </Typography.Headline6>
      )}
    </div>
  )
}
