import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState, useRef, Fragment } from 'react'
import axios, { CancelTokenSource } from 'axios'
import cn from 'classnames'
import moment from 'moment'
import { onMessage } from 'firebase/messaging'
import NavLink from '../../components/NavLink/NavLink'
import { Drawer } from '../../components/Drawer'
import { NotificationCard } from './NotificationCard/NotificationCard'
import { Switch } from '../../components/Switch'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import { Chips } from '../../components/Chips/Chips'
import {
  getIsModalOpen,
  getUnreadNotificationsAmount
} from '../../redux/store/notifications/getters'
import { MouseEvent } from 'react'
import {
  setIsModalOpen,
  setUnreadNotificationsAmount,
  setToken
} from '../../redux/store/notifications/slice'
import { setLoading } from '../../redux/store/common/slice'
import { formatFirebaseResponse, getIsFirstOfTheDay, getIsToday } from './utils'
import { getUser } from '../../redux/store/user/getters'
import {
  getDevice,
  getBadgeCount,
  getNotificationsList,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  initDevice
} from './api'
import { getTokenInit, messaging } from '../../firebase'
import { ReactComponent as NotificationsIcon } from 'assets/svg/Notifications.svg'
import { ReactComponent as EmptyBox } from 'assets/svg/EmptyBox.svg'
import { FirebaseMessageData, MarkAsReadData, NotificationItem } from './types'
import { BTN_TXT, CONTRACT_BULK_CREATION_MESSAGE_TYPE } from '../../constants'
import './styles.scss'
import {
  TNotificationType,
  useBottomNotificationsContext
} from '../BottomNotificationProvider/BottomNotificationsContextProvider'

export const Notifications = () => {
  const [isUnreadOnly, setIsUnreadOnly] = useState(false)
  const [notificationsList, setNotificationsList] = useState<
    NotificationItem[]
  >([])
  const cancelToken = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const isOpen = useSelector(getIsModalOpen)
  const unreadNotifications = useSelector(getUnreadNotificationsAmount)
  const user = useSelector(getUser)

  const { actions } = useBottomNotificationsContext()

  useEffect(() => {
    document.addEventListener('click', onClickOutside)
    return () => document.removeEventListener('click', onClickOutside)
  }, [])

  useEffect(() => {
    if (user.uuid) {
      initNotifications()
      onMessage(messaging, (message) => {
        if (message?.data?.type === CONTRACT_BULK_CREATION_MESSAGE_TYPE) {
          const { status, job_id } = message.data
          actions.onChangeNotification({
            id: job_id,
            type:
              status === 'succeed'
                ? TNotificationType.SUCCESS
                : TNotificationType.ERROR
          })
        } else {
          const notification = formatFirebaseResponse(
            message.data as FirebaseMessageData,
            message.messageId
          )
          setNotificationsList((prev) => [notification, ...prev])
          dispatch(
            setUnreadNotificationsAmount(
              message.data?.badge_count ? +message.data.badge_count : 0
            )
          )
        }
      })
    }
  }, [user.uuid])

  const initNotifications = async () => {
    let token = ''
    try {
      token = await getTokenInit()
      dispatch(setToken(token))
      await getDevice(token)
      await onGetNotifications()
      await onGetUnreadNotifications()
    } catch (e) {
      if (token) {
        await initDevice(token)
      }
    } finally {
    }
  }

  const onGetUnreadNotifications = async () => {
    try {
      if (!!cancelToken.current) {
        cancelToken.current.cancel()
      }
      cancelToken.current = axios.CancelToken.source()
      const response = await getBadgeCount(cancelToken.current?.token)
      dispatch(setUnreadNotificationsAmount(response.data.badge_count))
    } finally {
    }
  }

  const onGetNotifications = async (unreadOnly?: boolean) => {
    try {
      const { data } = await getNotificationsList(unreadOnly)
      setNotificationsList(data.results)
    } finally {
    }
  }

  const onShowUnreadOnly = async () => {
    setIsUnreadOnly((prev) => !prev)
    await onGetNotifications(!isUnreadOnly)
  }

  const onMarkAllAsRead = async () => {
    try {
      dispatch(setLoading(true))
      await markAllNotificationsAsRead()
      await onGetNotifications(isUnreadOnly)
      await onGetUnreadNotifications()
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onMarkAsRead = async (id: string, data: MarkAsReadData) => {
    try {
      const { data: response } = await markNotificationAsRead(id, data)
      setNotificationsList((prev) =>
        prev.map(
          (i) =>
            ({
              ...i,
              is_read: i.uuid === id ? response.is_read : i.is_read
            } as NotificationItem)
        )
      )
      await onGetUnreadNotifications()
    } finally {
    }
  }

  const onOpenModal = (e: MouseEvent) => {
    e.preventDefault()
    if (isOpen) {
      onCloseModal()
    } else {
      dispatch(setIsModalOpen(true))
    }
  }

  const onCloseModal = () => {
    dispatch(setIsModalOpen(false))
    setIsUnreadOnly(false)
  }

  const onClickOutside = (e) => {
    if (
      !e.target.closest('.notifications-drawer') &&
      !e.target.closest('.notifications-link')
    ) {
      onCloseModal()
    }
  }

  return (
    <>
      <NavLink
        label="Notifications"
        hrefTo=""
        onClick={onOpenModal}
        Icon={NotificationsIcon}
        className="notifications-link row space-between"
      >
        {!!unreadNotifications && (
          <Chips.Menu>{unreadNotifications}</Chips.Menu>
        )}
      </NavLink>
      <Drawer
        onClose={onCloseModal}
        closeIcon={null}
        mask={false}
        width={396}
        title={
          <>
            <div className="notifications-drawer__title">
              Notifications{' '}
              {!!unreadNotifications && (
                <span className="notifications-drawer__counter">
                  ({unreadNotifications})
                </span>
              )}
            </div>
            <div className="flex space-between align-center">
              <div className="notifications-drawer__switch flex align-center">
                <Switch checked={isUnreadOnly} onChange={onShowUnreadOnly} />
                <Typography.Body1>Unread only</Typography.Body1>
              </div>
              <Button onClick={onMarkAllAsRead} type={BUTTON_TYPES.LINK}>
                {BTN_TXT.MARK_ALL_AS_READ}
              </Button>
            </div>
          </>
        }
        className="notifications-drawer"
        visible={isOpen}
        contentWrapperStyle={{ position: 'absolute' }}
        placement="left"
      >
        <div className="notifications-drawer__container column">
          <div
            className={cn('notifications-drawer__content column', {
              'notifications-drawer__content--empty': !notificationsList.length
            })}
          >
            {!!notificationsList.length ? (
              <>
                {notificationsList.map((i, ind, arr) => (
                  <Fragment key={i.uuid}>
                    {(ind === 0 || getIsFirstOfTheDay(i, arr[ind - 1])) && (
                      <Typography.Label
                        className="notifications-drawer__date"
                        weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
                      >
                        {moment(i.created_at).format('MM/DD/YYYY')}
                        {getIsToday(i.created_at) && ' - Today'}
                      </Typography.Label>
                    )}
                    <NotificationCard
                      createdAt={i.created_at}
                      isRead={i.is_read}
                      id={i.uuid}
                      onMarkAsRead={onMarkAsRead}
                      description={i.description}
                    />
                  </Fragment>
                ))}
              </>
            ) : (
              <>
                <EmptyBox />
                <Typography.Headline6
                  className="notifications-drawer__empty-msg"
                  weight={TYPOGRAPHY_WEIGHT.BOLD}
                >
                  No notifications
                </Typography.Headline6>
              </>
            )}
          </div>
        </div>
      </Drawer>
    </>
  )
}
