import moment from 'moment'
import { FirebaseMessageData, NotificationItem } from './types'

export const getIsFirstOfTheDay = (
  prev: NotificationItem,
  current: NotificationItem
) => moment(current.created_at).date() > moment(prev.created_at).date()

export const getIsToday = (date: string) => moment().isSame(moment(date), 'day')

export const formatFirebaseResponse = (
  data: FirebaseMessageData,
  id: string
): NotificationItem => ({
  title: data.title,
  description: data.description,
  created_at: data.created_at,
  type: data.type,
  uuid: id,
  recipient: '',
  producer: '',
  is_read: false
})
