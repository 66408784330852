import { HealthSystemDetailsData } from './types'
import { MY_COMMUNITY_TABS } from '../../constants'

export const initialHealthSystemDetails: HealthSystemDetailsData = {
  uuid: '',
  name: '',
  email: '',
  status: '',
  vice_president: {
    uuid: '',
    first_name: '',
    last_name: '',
    role: '',
    status: '',
    email: '',
    timezone: {
      uuid: '',
      name: '',
      offset: ''
    }
  },
  address: {
    state: '',
    street: '',
    zip_code: '',
    city: ''
  }
}

export const BREADCRUMBS = (title: string) => [
  {
    children: 'My Community',
    href: '/my-community',
    state: { redirectTab: MY_COMMUNITY_TABS[0].key }
  },
  {
    children: title
  }
]
