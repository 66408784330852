import { Container } from 'components/Container/Container'
import { useSelector } from 'react-redux'
import { getCommunityInfo } from '../../redux/store/chapterInfo/getters'
import InvoiceDataContextProvider from './Providers/InvoiceDataContextProvider'
import UploadTemplate from './UploadTemplate'
import InvoiceDataTable from '../../features/InvoiceData/InvoiceDataTable'

const InvoiceData = () => {
  const communityInfo = useSelector(getCommunityInfo)
  return (
    <InvoiceDataContextProvider>
      <Container
        className="invoice-data-container"
        title="Invoice data"
        actions={<UploadTemplate />}
        subtitle={communityInfo?.name}
      >
        <InvoiceDataTable />
      </Container>
    </InvoiceDataContextProvider>
  )
}

export default InvoiceData
