import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { CommonsUsersRequest, CommonsUsersResponse } from './types'

const fetcher = new Fetcher({})

export const getCommonsUsers: CommonsUsersRequest = async (role, data) =>
  fetcher.request<unknown, CommonsUsersResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.USERS}${API_DICTIONARY.COMMONS}${role}/`,
    method: HTTP_METHODS.GET,
    ...data
  })
