import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { AllowedUsersDataResponse, FilterListResponse } from './types'

const fetcher = new Fetcher({})

export const getAllowedRoles = async () =>
  fetcher.request<unknown, AllowedUsersDataResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.ALLOWED_ROLES}`,
    method: HTTP_METHODS.GET
  })

export const getContractCategoriesList = () =>
  fetcher.request<unknown, FilterListResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.CONTRACT_CATEGORIES}`,
    method: HTTP_METHODS.GET
  })

export const getDepartmentsList = (hsId: string) =>
  fetcher.request<unknown, FilterListResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.HEALTH_SYSTEMS}${hsId}/${API_DICTIONARY.DEPARTMENTS}`,
    method: HTTP_METHODS.GET
  })

export const getHospitalsList = (hsId: string) =>
  fetcher.request<unknown, FilterListResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.HEALTH_SYSTEMS}${hsId}/${API_DICTIONARY.HOSPITALS}`,
    method: HTTP_METHODS.GET
  })
