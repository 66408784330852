import { Forms } from 'components/Forms'
import { TUserForm } from 'components/Forms/forms.user.d'
import { useDispatch, useSelector } from 'react-redux'
import { getCommunityInfo } from 'redux/store/chapterInfo/getters'
import { getUser } from 'redux/store/user/getters'
import { fetchUser } from 'redux/store/user/slice'
import { updateUserProfile } from './api'
import { TUserProfile, UserData } from './types'
import { useState } from 'react'

export const UserProfile = ({ setActiveTab }: TUserProfile) => {
  const [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const communityInfo = useSelector(getCommunityInfo)
  const onSubmit = (data: TUserForm) => {
    const dataToSend: UserData = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number || '',
      avatar_id: data?.avatar,
      timezone_id: data?.timezone_id,
      title: data?.title || null,
      years_of_service: data?.years_of_service || null,
      hobbies: data?.hobbies || null,
      vendor_preferences: data?.vendor_preferences || null
    }
    if ('hide_phone_number_for_vendor' in data) {
      dataToSend.hide_phone_number_for_vendor =
        data?.hide_phone_number_for_vendor
    }
    updateUserProfile(dataToSend).then(() => {
      dispatch(fetchUser())
      setIsActive(false)
    })
  }
  return (
    <Forms.User
      setActiveTab={setActiveTab}
      onSubmit={onSubmit}
      initialState={{
        ...user,
        community: communityInfo?.name,
        avatar: user?.avatar_logo?.file || null
      }}
      isProfile
      setIsActive={setIsActive}
      isActive={isActive}
    />
  )
}
