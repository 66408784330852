export const MY_COMMUNITY_TABS = [
  {
    key: 'communities',
    label: 'Communities'
  },
  {
    key: 'vendors',
    label: 'Vendors'
  },
  {
    key: 'commons',
    label: 'Сommons'
  }
]
