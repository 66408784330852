import { useMemo } from 'react'
import Typography from 'components/Typography/Typography'
import { ReactComponent as WarningIcon } from 'assets/svg/WarningIcon.svg'
import './styles.scss'

export type TProps = {
  usedNames: string[]
  search: string
}

function findCommonPart(inputString, vendorList) {
  const pattern = /[a-zA-Z]{5}/gi
  const inputMatches = inputString.match(pattern)

  if (!inputMatches) {
    return null
  }

  for (const match of inputMatches) {
    if (vendorList.find((i) => i.includes(match))) {
      return match
    }
  }

  return null
}

const WarningLabel = ({ usedNames, search }: TProps) => {
  const regex = useMemo(() => {
    return new RegExp(findCommonPart(search, usedNames), 'gi')
  }, [search, usedNames])

  return (
    <div className="warning-label-container row">
      <WarningIcon />
      <div className="warning-label-container__content">
        <Typography.Body2>
          Please make sure that you are creating a unique vendor
        </Typography.Body2>
        <div className="row">
          <Typography.Caption
            dangerouslySetInnerHTML={{
              __html:
                `Vendors with a similar name in the system: ${usedNames.map(
                  (i) => i
                )}.`.replaceAll(
                  regex,
                  (match) => `<span class="search-param">${match}</span>`
                )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default WarningLabel
