import Fetcher from '../../services/Fetcher'
import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination
} from '../../constants'
import {
  TInvoiceDataResult,
  TInvoiceList
} from '../../features/InvoiceData/types'
import {
  TUploadPurchaseOrders,
  TUploadPurchaseOrdersResponse
} from '../../features/PurchaseOrders/types'

const fetcher = new Fetcher({})

export const getInvoiceDataTemplate = async () =>
  fetcher.request<unknown, ResponseWithPagination<TInvoiceDataResult>>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.INVOICE_DATA_TEMPLATE}`,
    method: HTTP_METHODS.GET
  })

export const uploadInvoices = async (data: TUploadPurchaseOrders) =>
  fetcher.request<TUploadPurchaseOrders, TUploadPurchaseOrdersResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })

export const getInvoiceData = async (data) =>
  fetcher.request<unknown, ResponseWithPagination<TInvoiceList>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}`,
    method: HTTP_METHODS.GET,
    ...data
  })
