import { Sorter } from '../../components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import { ReactComponent as Filter16 } from '../../assets/svg/Filter16.svg'
import { ColumnsType } from 'antd/es/table'
import { ReactComponent as FilterApplied16 } from '../../assets/svg/FilterApplied16.svg'
import {
  TInvoiceFiltersFunc,
  TInvoiceList,
  TInvoicesColumnsFunc,
  TInvoicesFilters
} from './types'
import moment from 'moment/moment'
import { timelineDateFormat } from '../../components/Timelines/constants'
import { Datepicker } from '../../components/Datepicker'
import { Dropdown } from '../../components/Dropdown'
import { AttachmentsList } from '../../components/AttachmentsList/AttachmentsList'
import CreatorCell from './CreatorCell'
import { fetchHealthSystemsList } from '../HealthSystems/api'

export const columns = ({
  applyFilters,
  filters,
  community,
  offset
}: TInvoicesColumnsFunc): ColumnsType<TInvoiceList> => [
  {
    dataIndex: 'created_at',
    key: 'created_at',
    width: 168,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TInvoiceList>
        dataIndex="created_at"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Upload date
      </Sorter>
    ),
    render: (_, { created_at }) =>
      created_at
        ? moment(created_at).zone(offset).format(timelineDateFormat)
        : '-',
    filterDropdown: (props) => {
      return (
        <div className="filter-dropdown-table-range">
          <Datepicker.Filter<TInvoicesFilters, TInvoiceFiltersFunc, unknown>
            applyFilters={applyFilters}
            appliedFilters={filters}
            field="created_at_range"
            {...props}
            prefixCls="range-picker-custom ant-picker"
            open={props.visible}
            rangeClassName="filter-dropdown-table-range-picker"
            placement="topLeft"
          />
        </div>
      )
    },
    filterIcon: !!filters?.created_at_range?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'creator',
    key: 'creator',
    width: 504,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TInvoiceList>
        dataIndex="creator"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Uploaded by
      </Sorter>
    ),
    render: (_, { creator }) => {
      return <CreatorCell creator={creator} />
    },
    filterDropdown: (props) => {
      return (
        <Dropdown.Filter<TInvoicesFilters, TInvoiceFiltersFunc, unknown>
          searchCallback={() => fetchHealthSystemsList(community)}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="creator"
          asyncSearch
          {...props}
        />
      )
    },
    filterIcon: !!filters?.creator?.length ? <FilterApplied16 /> : <Filter16 />,
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'invoice',
    key: 'invoice',
    width: 467,
    title: 'invoices ',
    render: (_, { invoice }) => (
      <AttachmentsList
        className="invoice-data"
        label=""
        files={[invoice]}
        disableActions
      />
    ),
    showSorterTooltip: false
  }
]
