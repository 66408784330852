export const routes = {
  portal: '/',
  confirmEmail: '/sign-up/confirm-email',
  setPassword: '/sign-up/set-password',
  storybook: '/storybook',
  profile: '/profile',
  login: '/login',
  settings: '/settings',
  dashboard: '/dashboard',
  compliance: '/compliance',
  contracts: '/contracts',
  myContracts: '/my_contracts',
  bidding: '/bidding',
  vendors: '/vendors',
  chat: '/chat',
  resetPassword: '/reset-password',
  createUserProfile: '/settings/profile',
  rfp: '/rfp',
  notFound: '/404',
  productRequests: '/product-requests',
  financials: '/financials',
  purchaseOrders: '/purchase-orders',
  invoiceData: '/invoice-data',
  faNumber: '/fa-phone-number',
  faCode: '/fa-code',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
  landing: '/landing',
  onboarding: '/onboarding',
  clinicalReview: '/clinical-review',
  cognusRepository: '/cognus_repository',
  opportunityAnalysis: '/opportunity_analysis',
  calendar: '/calendar',
  contractPipeline: '/contract_pipeline',
  checkIns: '/check-ins',
  myCommunity: '/my-community',
  vendorDetails: '/vendor-details',
  bulkContractCreation: '/bulk_contract_creation',
  verifyEmail: '/verify-email',
  pendingApproval: '/pending-approval',
  healthSystem: '/health-system',
  marketInsights: '/market-insights'
} as const
