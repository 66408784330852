import { useEffect, useRef, useState } from 'react'
import { DEFAULT_PAGE } from '../../components/Table/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from '../../hooks/hooks'
import { setLoading } from '../../redux/store/common/slice'
import { Input } from '../../components/Input'
import { PLACEHOLDERS } from '../../constants'
import { Table } from '../../components/Table'
import { columns } from './constants'
import { ReactComponent as Close16 } from '../../assets/svg/Clos16.svg'
import { ReactComponent as Search16 } from '../../assets/svg/Search16.svg'
import InvoiceDataUpload from './InvoiceDataUpload'
import {
  TInvoiceList,
  TInvoiceFiltersFunc,
  TInvoicesRequestData,
  TInvoicesFilters,
  TInvoiceParams
} from './types'
import { getInvoiceData } from '../../pages/InvoiceData/api'
import './style.scss'
import { getUser } from '../../redux/store/user/getters'

const InvoiceDataTable = () => {
  const user = useSelector(getUser)
  const [tableData, setTableData] = useState<TInvoiceList[]>([])
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [totalNumber, setTotalNumber] = useState(0)
  const [sortParams, setSortParams] = useState<TInvoicesRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearch = useDebounce(searchValue, 500)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [filters, setFilters] = useState<TInvoicesFilters>({})

  const dispatch = useDispatch()

  const applyFilters: TInvoiceFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TInvoicesRequestData) => {
    dispatch(setLoading(true))
    const params: TInvoiceParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    getInvoiceData({ params })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleSearch = (val: string) => setSearchValue(val)

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const onSearchChangeValue = (e) => {
    handleSearch(e.target.value)
  }

  const onFinish = () => {
    getTableData({ ...sortParams, ...filters })
  }

  return (
    <div className="table-wrapper purchase-orders">
      <div className="table-wrapper__header">
        {
          <>
            <Input
              className="allow-clear"
              propsInput={{
                allowClear: { clearIcon: <Close16 /> },
                placeholder: PLACEHOLDERS.SEARCH,
                prefix: <Search16 />,
                value: searchValue,
                onChange: onSearchChangeValue
              }}
            />
            <InvoiceDataUpload onFinish={onFinish} />
          </>
        }
      </div>
      {!firstLoad && (
        <Table<TInvoiceList>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters: filters,
            community: user?.community || '',
            offset: user.timezone.offset
          })}
          className="purchase-orders-table"
          hasSearchOrFilters={true}
          pageSize={pageInfo.pageSize}
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          rowSelection={undefined}
        />
      )}
    </div>
  )
}

export default InvoiceDataTable
