import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { notification, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ReactComponent as SuccessIcon } from '../../assets/svg/SuccessIcon.svg'
import { ReactComponent as ErrorIcon } from '../../assets/svg/ErrorIcon.svg'
import moment from 'moment'

export enum TNotificationType {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info'
}
type TNotification = {
  id: string
  type?: TNotificationType
  numberOfContracts?: number
  date?: string
}

type ContextProps = {
  actions: {
    openNotification: (
      t: TNotification,
      setNotificationToTheLocalStorage: boolean
    ) => void
    onCloseNotification: (id) => void
    onChangeNotification: (t: TNotification) => void
    destroyAllNotifications: () => void
  }
}

const BottomNotificationsContext = createContext<ContextProps>({
  actions: null!
})

const BottomNotificationsContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    // get notifications from local store on page refresh
    const notifications = localStorage.getItem('loadingContracts')
    if (notifications) {
      JSON.parse(notifications).map((currentNotification) => {
        openNotification(currentNotification)
      })
    }
  }, [])

  const getTitle = useCallback(
    ({
      type,
      numberOfContracts
    }: Pick<TNotification, 'type' | 'numberOfContracts'>) => {
      return type === 'info'
        ? `Creating ${numberOfContracts} contract${
            numberOfContracts === 1 ? '' : 's'
          }`
        : type === 'success'
        ? `${numberOfContracts} contract${
            numberOfContracts === 1 ? '' : 's'
          } created`
        : 'Contracts creation failed.'
    },
    []
  )

  const onCloseNotification = useCallback((id) => {
    // remove notification from Local store when user closes the notification
    const notifications = localStorage.getItem('loadingContracts')
    if (notifications) {
      const parsedNotifications = JSON.parse(notifications)
      const currentNotification = parsedNotifications.find((n) => n.id === id)

      if (currentNotification.type === TNotificationType.INFO) {
        // remove date to hide the notification
        delete currentNotification.date
        localStorage.setItem(
          'loadingContracts',
          JSON.stringify([
            ...parsedNotifications.filter((n) => n.id !== id),
            currentNotification
          ])
        )
      } else {
        localStorage.setItem(
          'loadingContracts',
          JSON.stringify(parsedNotifications.filter((n) => n.id !== id))
        )
      }
    }
  }, [])

  const openNotification = useCallback(
    (
      {
        id,
        type = TNotificationType.SUCCESS,
        numberOfContracts,
        date
      }: TNotification,
      setNotificationToLocalStorage?: boolean
    ) => {
      // open notification and add it to the Local Storage
      const icon =
        type === TNotificationType.INFO ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
        ) : type === TNotificationType.SUCCESS ? (
          <SuccessIcon className="bottom-notification__icon" />
        ) : (
          <ErrorIcon className="bottom-notification__icon" />
        )
      const text = 'Please try again.'

      const title = getTitle({ type, numberOfContracts })

      const duration = date
        ? moment.duration(moment().diff(moment(date))).asMinutes()
        : 0

      const hideCloseButton = type === TNotificationType.INFO && duration <= 30

      if (type === TNotificationType.INFO ? !!date : true) {
        api.open({
          className: 'bottom-notification',
          message: title,
          description: type === TNotificationType.ERROR ? text : null,
          duration: 0,
          icon,
          placement: 'bottomRight',
          onClose: () => onCloseNotification(id),
          closeIcon: hideCloseButton ? <></> : undefined,
          key: id
        })
      }

      if (setNotificationToLocalStorage) {
        const notifications = localStorage.getItem('loadingContracts') ?? '[]'
        const newNotification = {
          id,
          type,
          numberOfContracts
        } as TNotification
        if (date) newNotification.date = date
        if (notifications) {
          // if we already have notification with this JOB id - remove it from the local store and add new item
          if (
            JSON.parse(notifications).find(
              (currentNotification) => currentNotification.id === id
            )
          ) {
            localStorage.setItem(
              'loadingContracts',
              JSON.stringify([
                ...JSON.parse(notifications).filter(
                  (currentNotification) => currentNotification.id !== id
                ),
                newNotification
              ])
            )
            // if not - just add it to the list
          } else {
            localStorage.setItem(
              'loadingContracts',
              JSON.stringify([...JSON.parse(notifications), newNotification])
            )
          }
        }
      }
    },
    [api]
  )

  const onChangeNotification = useCallback(({ id, type }: TNotification) => {
    // if it is some response from the BE - close prev notification & open new with status from response
    const notifications = localStorage.getItem('loadingContracts')
    if (notifications) {
      JSON.parse(notifications).forEach((currentNotification) => {
        if (currentNotification.id === id) {
          notification.close(id)
          openNotification(
            {
              id,
              type,
              numberOfContracts: currentNotification.numberOfContracts
            },
            true
          )
        }
      })
    }
  }, [])

  const destroyAllNotifications = useCallback(() => {
    const notifications = localStorage.getItem('loadingContracts')
    if (notifications) {
      JSON.parse(notifications).forEach(({ id }) => {
        notification.close(id)
      })
      localStorage.setItem('loadingContracts', '[]')
    }
  }, [])

  const context = useMemo(
    () => ({
      actions: {
        onCloseNotification,
        openNotification,
        onChangeNotification,
        destroyAllNotifications
      }
    }),
    [
      onCloseNotification,
      openNotification,
      onChangeNotification,
      destroyAllNotifications
    ]
  )

  return (
    <BottomNotificationsContext.Provider value={context}>
      {children}
      {contextHolder}
    </BottomNotificationsContext.Provider>
  )
}

export const useBottomNotificationsContext = () =>
  useContext(BottomNotificationsContext)

export default BottomNotificationsContextProvider
