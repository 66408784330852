import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { TRFPDocument } from '../../../features/RFP/RFPDetails/types'
import { TInvoiceDataResult } from '../../../features/InvoiceData/types'
import { ROLES } from 'features/Permission'
import { getInvoiceDataTemplate } from '../api'

type ContextProps = {
  state: {
    files: TRFPDocument[]
  }
  actions: {}
}
const InvoiceDataContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const InvoiceDataContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [files, setFiles] = useState<TInvoiceDataResult[]>([])

  useEffect(() => {
    if (user.role !== ROLES.COMMUNITY_PRESIDENT)
      getInvoiceDataTemplate().then((res) =>
        setFiles(
          res.data.results.map((i, idx) => ({
            ...i,
            uuid: `${idx}`
          }))
        )
      )
  }, [dispatch])

  const context = useMemo(
    () => ({
      state: {
        files
      },
      actions: {}
    }),
    [files]
  )
  return (
    <InvoiceDataContext.Provider value={context}>
      {children}
    </InvoiceDataContext.Provider>
  )
}
export const useInvoiceDataContext = () => useContext(InvoiceDataContext)

export default InvoiceDataContextProvider
