import { Status, USER_STATUS_TYPES_TO_CLASSNAME } from 'components/Status'
import { Link } from 'components/Link'
import { Dropdown } from 'components/Dropdown'
import { Sorter } from 'components/Sorter/Sorter'
import { DescriptionCell } from 'components/DescriptionCell/DescriptionCell'
import { Avatar } from '../../../../components/Avatar/Avatar'
import { StartChat } from '../../../../components/StartChat/StartChat'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { getContractCategoriesList } from '../../api'
import { fullFormatPhoneNumber } from 'helper/common'
import { USER_STATUSES_OPTIONS } from 'constants/common'
import { ACTIONS, ability, SUBJECTS } from 'features/Permission'
import { MY_COMMUNITY_TABS } from '../../constants'
import { routes } from 'router'
import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import {
  VendorUser,
  VendorUsersFiltersFunc,
  VendorUsersColumnsFunc,
  VendorUsersFilters
} from './types'

export const columns = ({
  applyFilters,
  filters,
  onStartChat
}: VendorUsersColumnsFunc): ColumnsType<VendorUser> => [
  {
    dataIndex: 'first_name',
    key: 'first_name',
    className: 'avatar-cell',
    width: 44,
    title: null,
    render: (_, { first_name, avatar_logo, last_name }) => (
      <Avatar
        name={`${first_name[0]?.toUpperCase()}${last_name[0]?.toUpperCase()}`}
        src={avatar_logo?.file_preview || ''}
        alt="user image"
      />
    ),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'name',
    key: 'name',
    width: 280,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<VendorUser>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        User name
      </Sorter>
    ),
    render: (_, { first_name, last_name, uuid }) =>
      ability.can(ACTIONS.VIEW, SUBJECTS.VENDOR_USERS) ? (
        <CellWithSubtitle
          title={
            <Link
              to={`${routes.createUserProfile}/${uuid}`}
              state={{ redirectTab: MY_COMMUNITY_TABS[1].key }}
            >
              {first_name} {last_name}
            </Link>
          }
        />
      ) : (
        `${first_name} ${last_name}`
      ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    width: 130,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<VendorUser>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<VendorUsersFilters, VendorUsersFiltersFunc, unknown>
        items={USER_STATUSES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="statuses"
        {...props}
      />
    ),
    render: (_, { status, uuid }) => (
      <div className="row gap-8 align-center">
        <Status.Default className={USER_STATUS_TYPES_TO_CLASSNAME[status]}>
          {status}
        </Status.Default>
        {status === 'Active' && <StartChat onClick={() => onStartChat(uuid)} />}
      </div>
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'email',
    key: 'email',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<VendorUser>
        dataIndex="email"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Email
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'phone_number',
    key: 'phone_number',
    title: 'Phone #',
    width: 196,
    render: (_, { phone_number }) =>
      phone_number ? fullFormatPhoneNumber(phone_number) : '-',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_categories',
    key: 'contract_categories',
    title: 'Contract Categories',
    filterDropdown: (props) => (
      <Dropdown.Filter<VendorUsersFilters, VendorUsersFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={getContractCategoriesList}
        field="contract_categories"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { contract_categories }) => {
      const text = contract_categories.map((i) => i.name).join(', ')
      return <DescriptionCell content={text} text={text} />
    },
    filterIcon: !!filters?.contract_categories?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  }
]
