import { ReactComponent as ErrorIcon } from 'assets/svg/ErrorIcon.svg'
import './banner.error.scss'
import Typography from '../Typography/Typography'
import { FC } from 'react'
import { BannerProps } from './types'
import cn from 'classnames'

const Error: FC<BannerProps> = ({ text, className }) => {
  return (
    <div className={cn('error-banner row gap-12 align-center', className)}>
      <ErrorIcon />
      <Typography.Body1 className="error-banner__content__text">
        {text}
      </Typography.Body1>
    </div>
  )
}
export default Error
