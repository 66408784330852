import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import { useComplianceContext } from '../../../../pages/Compliance/Providers/ComplianceContextProvider'
import { TNonComplianceProducts } from '../../../../pages/Compliance/types'
import InfiniteList from '../../../../components/InfiniteList/InfiniteList'
import { useMemo } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import RowItem from './RowItem'
import './style.scss'

const SpendByProduct = () => {
  const { loadMoreNonCompliantProduct, nonComplianceProducts } =
    useComplianceContext().state
  const Loader = useMemo(
    () => <LoadingOutlined className="chat-list-feature-list__spinner" spin />,
    []
  )

  return (
    <div className="compliance-container__chart-wrapper charts_ongoing-contracts_container spend-by-product">
      <Typography.Body1
        className="compliance-container__chart-wrapper__title"
        weight={TYPOGRAPHY_WEIGHT.BOLD}
      >
        Spend by Non-Compliant Product
      </Typography.Body1>
      {!!nonComplianceProducts.length && (
        <div className="spend-by-product__table-header full-width row align-center">
          <Typography.Label
            className="spend-by-product__table-header__column"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Vendor
          </Typography.Label>
          <Typography.Label
            className="spend-by-product__table-header__column"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Product Name
          </Typography.Label>
          <Typography.Label
            className="spend-by-product__table-header__column"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Spend
          </Typography.Label>
        </div>
      )}
      <InfiniteList<TNonComplianceProducts>
        items={nonComplianceProducts}
        loader={Loader}
        renderItem={RowItem}
        loading={false}
        keyExtractor={(item: TNonComplianceProducts) =>
          `${item.product_name}_${item.vendor_name}`
        }
        hasNextPage={loadMoreNonCompliantProduct.hasNextPage}
        onLoadMore={loadMoreNonCompliantProduct.handleLoadMore}
        listEmptyComponent={
          <div className="full-width full-height row align-center justify-center empty-state">
            <Typography.Body1>The list is empty</Typography.Body1>
          </div>
        }
        className="chat-list-feature-list"
      />
    </div>
  )
}

export default SpendByProduct
