import { BTN_TXT, VALIDATION_MESSAGES } from 'constants/txt'
import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { subject } from '@casl/ability'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../redux/store/user/getters'
import { setLoading } from '../../redux/store/common/slice'
import { notification } from '../../components/Notification'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { PRICE_FILE_TYPES } from '../../components/FileUpload/constants'
import { validateFileTypes } from '../../helper/common'
import { uploadInvoices } from '../../pages/InvoiceData/api'

type TProps = {
  onFinish: () => void
}

export default ({ onFinish }: TProps) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const handleUploadFinanceFiles = async (
    files: File[],
    documentType: string
  ) => {
    try {
      dispatch(setLoading(true))

      const formData = new FormData()
      formData.append(documentType, files[0], files[0].name)

      const result = await uploadInvoices({
        file: formData.get(documentType)
      })
      if (result.data.uuid) {
        notification.success({ message: VALIDATION_MESSAGES.SM0044 })
        onFinish()
      }
    } catch (e: any) {
      notification.error({
        message: e?.data.errors ? e?.data.errors : e?.data.file
      })
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <Can I={ACTIONS.UPLOAD} a={subject(SUBJECTS.INVOICE_DATA, { ...user })}>
      <FileUpload
        uploadButtonText={BTN_TXT.UPLOAD_INVOICES}
        uploadBtnText={''}
        documentType="price_file"
        uploadFilesProps={{
          multiple: false,
          maxSize: 5242880,
          onDropAccepted: (file) =>
            handleUploadFinanceFiles(file, 'price_file'),
          accept: {
            ...PRICE_FILE_TYPES
          },
          validator: (file) => validateFileTypes(PRICE_FILE_TYPES, file)
        }}
        handleUploadFile={handleUploadFinanceFiles}
        tooltipText={''}
      />
    </Can>
  )
}
