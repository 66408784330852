import { DatePicker, Form } from 'antd'
import cn from 'classnames'
import './datepicker.default.scss'
import { TDatePickerDefault as Props } from './types'
import { KeyboardEvent } from 'react'
import { ReactComponent as ArrowRight16 } from 'assets/svg/ArrowRight16.svg'
import { ReactComponent as ArrowLeft16 } from 'assets/svg/ArrowBack16.svg'

export const Default = ({ className, ...props }: Props) => {
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!/^(\d|Backspace|\/)$/.test(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <Form.Item
      className={cn('custom-datepicker datepicker-default', className)}
      colon={false}
      {...props.propsItem}
    >
      <DatePicker
        format="DD.MM.YYYY"
        getPopupContainer={(node) => node}
        nextIcon={<ArrowRight16 />}
        prevIcon={<ArrowLeft16 />}
        superNextIcon={false}
        superPrevIcon={false}
        {...props.propsDate}
        allowClear={false}
        onKeyDown={onKeyDown}
        dropdownClassName="datepicker-dropdown"
      />
    </Form.Item>
  )
}
