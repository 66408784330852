import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'

import './styles.scss'

import { IChat } from 'redux/store/chats/types'
import { getIsLoading } from 'redux/store/chats/getters'
import InfiniteList from 'components/InfiniteList/InfiniteList'
import ListItem from 'features/ChatList/ListItem/ListItem'
import { createChatRequest } from '../api'
import { useChatListContext } from 'features/ChatList/Providers/ChatListContextProvider'
import { EmptyChats } from './Empty'
import useRouter from '../../../hooks/useRouter'
import { routes } from '../../../router'
import { setLoading } from '../../../redux/store/common/slice'

const List = () => {
  const isLoading = useSelector(getIsLoading)
  const dispatch = useDispatch()

  const router = useRouter()

  const chatListContext = useChatListContext()

  useEffect(() => {
    const receiver = localStorage.getItem('receiver')
    if (receiver) {
      dispatch(setLoading(true))
      createChatRequest({ type: 'private', members: [receiver] }).then(
        (res) => {
          router.navigate(`${routes.chat}/${res.data.uuid}`, { replace: true })
          localStorage.removeItem('receiver')
          dispatch(setLoading(false))
        }
      )
    }
  }, [])

  const Loader = useMemo(
    () => <LoadingOutlined className="chat-list-feature-list__spinner" spin />,
    []
  )

  return (
    <InfiniteList<IChat>
      items={chatListContext.state.chats}
      loader={Loader}
      renderItem={ListItem}
      loading={isLoading}
      keyExtractor={(item) => item.uuid}
      hasNextPage={chatListContext.state.hasNextPage}
      listEmptyComponent={<EmptyChats />}
      onLoadMore={chatListContext.actions.handleLoadMore}
      className="chat-list-feature-list"
    />
  )
}

export default List
