import { Typography } from 'components/Typography'
import { currencyFormatter } from '../../features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { ReactElement } from 'react'
// import { capitalizeFirstLetter } from '../../utils/common'

interface IChartLegendPart {
  color: 'red' | 'green' | 'blue' | 'yellow'
  name: string
  price: string | number
  children?: ReactElement
}

export const FinancialsChartLegendPart = ({
  color,
  name,
  price,
  children
}: IChartLegendPart) => {
  return (
    <div className="legend__part row align-center">
      <div className={`square ${color}`} />
      <Typography.Headline6
        className="legend__category row align-center"
        weight="normal"
      >
        {name}
        {children}
      </Typography.Headline6>
      <Typography.Headline6 className="legend__price" weight="semi-bold">
        {`$${currencyFormatter(Number(price)?.toFixed(2))}`}
      </Typography.Headline6>
    </div>
  )
}
