import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { HealthSystemUsersRequest, HealthSystemUsersResponse } from './types'

const fetcher = new Fetcher({})

export const getHealthSystemUsers: HealthSystemUsersRequest = async (
  hsId,
  role,
  data
) =>
  fetcher.request<unknown, HealthSystemUsersResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.USERS}${API_DICTIONARY.HEALTH_SYSTEM}${hsId}/${role}/`,
    method: HTTP_METHODS.GET,
    ...data
  })
