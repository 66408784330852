import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import RadioGroup from '../../../../components/RadioGroup/RadioGroup'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { Table } from '../../../../components/Table'
import { Input } from '../../../../components/Input'
import { Menu } from '../../../../components/Menu/Menu'
import { UserArchivePopup } from '../../../../pages/CRUDUser/UserArchivePopup'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import useRouter from '../../../../hooks/useRouter'
import { setLoading } from '../../../../redux/store/common/slice'
import { useDebounce } from '../../../../hooks/hooks'
import { getUser } from '../../../../redux/store/user/getters'
import {
  fullFormatPhoneNumber,
  isTableHasParams
} from '../../../../helper/common'
import { getHealthSystemUsers } from './api'
import { getAllowedRolesList } from '../../../../redux/store/myCommunity/getters'
import { ability, ACTIONS, Can, ROLES, SUBJECTS } from '../../../Permission'
import { routes } from '../../../../router'
import { BTN_TXT, PLACEHOLDERS, USER_STATUSES } from '../../../../constants'
import { DEFAULT_PAGE } from '../../../../components/Table/constants'
import { filterColumns } from './utils'
import { onStartChat } from '../../utils'
import { columns, USER_STATUSES_TABS } from './constants'
import { RadioChangeEvent } from 'antd'
import { FC } from 'react'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import {
  HealthSystemUser,
  HealthSystemUsersParams,
  HealthSystemUsersFilters,
  HealthSystemTableRequestData,
  HealthSystemUsersFiltersFunc,
  HealthSystemUsersProps
} from './types'
import './styles.scss'

export const HealthSystemUsers: FC<HealthSystemUsersProps> = ({ hsId }) => {
  const [searchValue, setSearchValue] = useState('')
  const [rolesList, setRolesList] = useState<ItemType[]>([])
  const [activeTab, setActiveTab] = useState('')
  const [tableData, setTableData] = useState<HealthSystemUser[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [sortParams, setSortParams] = useState<HealthSystemTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [filters, setFilters] = useState<HealthSystemUsersFilters>({})
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [selectedRows, setSelectedRows] = useState<HealthSystemUser[]>([])
  const [usersStatus, setUsersStatus] = useState(USER_STATUSES_TABS[0].value)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const activeTabRef = useRef(activeTab)
  const user = useSelector(getUser)
  const allowedRoles = useSelector(getAllowedRolesList)
  const dispatch = useDispatch()
  const { push } = useRouter()

  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (activeTab) {
      timeoutRef.current = setTimeout(
        () => getTableData({ ...sortParams, ...filters }),
        firstLoad || activeTab !== activeTabRef.current ? 0 : 500
      )
    }
  }, [debouncedSearch, activeTab, usersStatus])

  useEffect(() => {
    if (!!allowedRoles.length) {
      const roles =
        allowedRoles.find((i) => i.tab_name === 'community')?.roles || []
      const selectedRole = !!roles.length ? roles[0].value : ''
      setRolesList(
        roles.map((i) => ({
          key: i.value,
          label: i.label
        }))
      )
      setActiveTab(selectedRole)
    }
  }, [allowedRoles.length])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: HealthSystemTableRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: HealthSystemUsersParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (usersStatus === USER_STATUSES_TABS[1].value) {
      params.statuses = [usersStatus]
      setFilters((prev) => ({
        ...prev,
        statuses: []
      }))
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getHealthSystemUsers(hsId, activeTab, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            name: i.first_name,
            phone_number: fullFormatPhoneNumber(i.phone_number),
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleSelectRow = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: HealthSystemUsersFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }

  const handleRedirect = () =>
    push(`${routes.createUserProfile}/${selectedRowKeys[0]}`, {
      state: { selectedHs: hsId }
    })

  const createUser = () =>
    push(`${routes.createUserProfile}/new?hsUser=${hsId}`, {
      state: { selectedHs: hsId }
    })

  const handleRadioGroup = ({ target: { value } }: RadioChangeEvent) =>
    setUsersStatus(value)

  return (
    <div className="hs-users-list column mt-16">
      <div className="hs-users-list__header row align-center space-between">
        <div className="row align-center gap-8">
          <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.BOLD}>
            Users
          </Typography.Headline6>
          {activeTab === ROLES.INFLUENCER && (
            <Can I={ACTIONS.CRUD} a={SUBJECTS.APPROVE_PENDING_USERS}>
              <RadioGroup.TimePeriod
                options={USER_STATUSES_TABS}
                defaultValue={USER_STATUSES_TABS[0].value}
                onChange={handleRadioGroup}
                value={usersStatus}
              />
            </Can>
          )}
        </div>
        <div className="hs-users-list__actions row align-center">
          <Input
            className="hs-users-list__search allow-clear"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              placeholder: PLACEHOLDERS.SEARCH_RFP_USERS,
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
          <Can I={ACTIONS.CRUD} a={SUBJECTS.HEALTH_SYSTEM_USERS}>
            {!selectedRowKeys.length && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Plus16 />}
                onClick={createUser}
                upperCase
              >
                {BTN_TXT.CREATE_USER}
              </Button>
            )}
          </Can>
          {selectedRowKeys.length === 1 && (
            <Can I={ACTIONS.EDIT} a={SUBJECTS.USER}>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                onClick={handleRedirect}
                icon={<Edit16 />}
                upperCase
              >
                {BTN_TXT.EDIT}
              </Button>
            </Can>
          )}
          {selectedRowKeys.length > 0 &&
            selectedRows.find((i) => i?.status !== USER_STATUSES.ARCHIVED) && (
              <>
                <UserArchivePopup<HealthSystemUser>
                  callback={() => {
                    setSelectedRowKeys([])
                    setSelectedRows([])
                    getTableData({ ...sortParams, ...filters })
                  }}
                  selected={selectedRows}
                  isTable
                />
              </>
            )}
        </div>
      </div>
      <Menu
        mode="horizontal"
        className="hs-users-list__users-tabs mt-12"
        onSelect={(e) => setActiveTab(e.key)}
        selectedKeys={[activeTab]}
        defaultSelectedKeys={[activeTab]}
        items={rolesList}
        key="value"
      />
      <Table<HealthSystemUser>
        dataSource={tableData}
        columns={filterColumns(
          activeTab,
          usersStatus,
          columns({ applyFilters, filters, hsId, onStartChat })
        )}
        emptyText="No users found"
        className="community-users-table"
        onChange={handleTableChange}
        onChangePage={handleChangePageSize}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        hasSearchOrFilters={isTableHasParams<HealthSystemUsersFilters>(
          debouncedSearch,
          filters
        )}
        createButton={
          ability.can(ACTIONS.CRUD, SUBJECTS.HEALTH_SYSTEM_USERS)
            ? {
                buttonText: BTN_TXT.CREATE_USER,
                availability: true,
                action: createUser
              }
            : undefined
        }
        rowSelection={
          ability.can(ACTIONS.CRUD, SUBJECTS.HEALTH_SYSTEM_USERS)
            ? {
                selectedRowKeys,
                onChange: handleSelectRow,
                getCheckboxProps: (record) => ({
                  disabled: record.uuid === user.uuid
                })
              }
            : undefined
        }
      />
    </div>
  )
}
