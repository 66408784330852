import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TChatTypeUnion } from 'constants/types'
import { CHAT_TYPE } from 'constants/common'
import { IChat, IChatsState } from 'redux/store/chats/types'
import {
  getChats,
  getSearch,
  getTotal,
  getType
} from 'redux/store/chats/getters'
import { getChatListActionAsync } from 'redux/store/chats/actions'
import { CHAT_LIST_TABS } from 'features/ChatList/constants'
import { ACTIONS, SUBJECTS } from 'features/ChatList/Abilities'
import { useChatListAbility } from 'features/ChatList/Providers/ChatListAbilityProvider'
import useTabs, { TabsReturnActions, TabsReturnState } from 'hooks/useTabs'
import useInfiniteData from 'hooks/useInfiniteData'
// import { getChatMembers } from '../../../redux/store/chatDetails/getters'

type ContextProps = {
  state: {
    hasNextPage: boolean
  } & TabsReturnState &
    Pick<IChatsState, 'chats' | 'type' | 'search'>
  actions: {
    handleLoadMore: VoidFunction
    setSearch: (value: string) => void
  } & TabsReturnActions
}

const ChatListContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const ChatListContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch<any>()
  const ability = useChatListAbility()

  const _type = useSelector(getType)
  const _search = useSelector(getSearch)
  const _chats = useSelector(getChats)
  // const _members = useSelector(getChatMembers)

  const [chats, setChats] = useState<IChat[]>(_chats)
  const [search, setSearch] = useState(_search)

  const timeoutRef = useRef<NodeJS.Timeout>()

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: CHAT_LIST_TABS,
    activeTab: _type || CHAT_LIST_TABS[0].key
  })

  const loadMore = useCallback(
    async (args) => {
      const typedActiveTab = tabsState.activeTab as TChatTypeUnion
      if (typedActiveTab !== CHAT_LIST_TABS[0].key) {
        await dispatch(
          getChatListActionAsync({ ...args, type: typedActiveTab })
        )
      } else {
        await dispatch(getChatListActionAsync({ ...args }))
      }
    },
    [dispatch, tabsState.activeTab]
  )

  useEffect(() => {
    return () => setSearch('')
  }, [])

  const total = useSelector(getTotal)

  const { limit, hasNextPage, handleLoadMore, reset } = useInfiniteData({
    total,
    limit: 20,
    loadMore
  })

  const _listenActiveTabChange = useCallback(() => {
    const typedActiveTab = tabsState.activeTab as TChatTypeUnion

    if (typedActiveTab !== CHAT_LIST_TABS[0].key) {
      dispatch(getChatListActionAsync({ limit, type: typedActiveTab }))
    } else {
      dispatch(getChatListActionAsync({ limit }))
    }
    reset()
    setChats([])
  }, [dispatch, limit, reset, tabsState.activeTab])
  const _listenSearchChange = useCallback(() => {
    if (search !== null) {
      if (search.length) {
        dispatch(getChatListActionAsync({ limit, search }))
      } else {
        dispatch(getChatListActionAsync({ limit }))
        tabsActions.setActiveTab(CHAT_LIST_TABS[0].key)
      }
    }
  }, [dispatch, limit, search, tabsActions])

  useEffect(() => {
    setChats(_chats)
  }, [_chats])

  useEffect(() => {
    _listenActiveTabChange()
  }, [_listenActiveTabChange])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(_listenSearchChange, 500)

    reset()
    setChats([])
  }, [_listenSearchChange, reset])

  useLayoutEffect(() => {
    tabsActions.setTabs(
      CHAT_LIST_TABS.map((i) => {
        const isChannelChatsDanger =
          i.key === CHAT_TYPE.CHANNEL &&
          ability.cannot(ACTIONS.VIEW, SUBJECTS.CHANNEL_CHATS)

        const isGroupChatsDanger =
          i.key === CHAT_TYPE.GROUP &&
          ability.cannot(ACTIONS.VIEW, SUBJECTS.GROUP_CHATS)

        const isPrivateChatsDanger =
          i.key === CHAT_TYPE.PRIVATE &&
          ability.cannot(ACTIONS.VIEW, SUBJECTS.PRIVATE_CHATS)

        return {
          ...i,
          danger:
            isChannelChatsDanger || isGroupChatsDanger || isPrivateChatsDanger
        }
      })
    )
  }, [ability, tabsActions])

  const context = useMemo(
    () => ({
      state: {
        ...tabsState,
        type: _type,
        search,
        chats,
        hasNextPage
      },
      actions: {
        ...tabsActions,
        handleLoadMore,
        setSearch
      }
    }),
    [tabsState, _type, search, chats, hasNextPage, tabsActions, handleLoadMore]
  )

  return (
    <ChatListContext.Provider value={context}>
      {children}
    </ChatListContext.Provider>
  )
}

export const useChatListContext = () => useContext(ChatListContext)

export default ChatListContextProvider
