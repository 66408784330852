import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import {
  currencyFormatter,
  sumPercentage
} from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import Typography from '../../../../components/Typography/Typography'
import { TLinearChart } from '../../../../pages/Compliance/types'
type TProps = {
  item: TLinearChart
}
const Chart = ({ item }: TProps) => {
  const { compliant, non_compliant } = item
  return (
    <div className="line-chart-compliance row space-between full-width align-center gap-24">
      {!!item?.name && (
        <Typography.Caption className="line-chart-compliance__title full-width">
          {item.name}
        </Typography.Caption>
      )}
      {!!item?.category && (
        <Typography.Caption className="line-chart-compliance__title full-width">
          {item.category}
        </Typography.Caption>
      )}
      <div className="table__chart">
        <Tooltip
          title={
            <div>
              Compliant <br />
              Spend: ${currencyFormatter(compliant || 0)} (
              {sumPercentage(compliant, non_compliant)}
              %) <br /> <br />
              Total spend: $
              {currencyFormatter((+non_compliant + +compliant).toFixed(2))}
            </div>
          }
          placement="topLeft"
        >
          <div
            style={{
              backgroundColor: '#73A8D3',
              height: '16px',
              width: `${(344 * +compliant) / (+compliant + +non_compliant)}px`
            }}
          />
        </Tooltip>
        <Tooltip
          title={
            <div>
              Non-compliant <br />
              Spend: ${currencyFormatter(non_compliant || 0)} (
              {sumPercentage(non_compliant, compliant)}
              %) <br /> <br />
              Total spend: $
              {currencyFormatter((+non_compliant + +compliant).toFixed(2))}
            </div>
          }
          placement="topLeft"
        >
          <div
            style={{
              backgroundColor: '#F99',
              height: '16px',
              width: `${
                (344 * +non_compliant) / (+compliant + +non_compliant)
              }px`
            }}
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default Chart
