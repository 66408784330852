import { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { Menu } from '../../../../components/Menu/Menu'
import { UserArchivePopup } from 'pages/CRUDUser/UserArchivePopup'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { useDebounce } from 'hooks/hooks'
import useRouter from 'hooks/useRouter'
import { getCommonsUsers } from './api'
import { setLoading } from 'redux/store/common/slice'
import { routes } from 'router/Config/config.routes'
import { getUser } from 'redux/store/user/getters'
import { getAllowedRolesList } from '../../../../redux/store/myCommunity/getters'
import { fullFormatPhoneNumber, isTableHasParams } from 'helper/common'
import { ability, ACTIONS, SUBJECTS, Can, ROLES } from 'features/Permission'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { USER_STATUSES } from 'constants/common'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { columns } from './constants'
import { onStartChat } from '../../utils'
import { MY_COMMUNITY_TABS } from '../../constants'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import {
  CommonsUser,
  CommonsUsersFiltersFunc,
  CommonsUsersRequestData,
  CommonsUsersParams,
  CommonsUsersFilters
} from './types'
import './styles.scss'

export const CommonsUsers = () => {
  const [activeTab, setActiveTab] = useState('')
  const [rolesList, setRolesList] = useState<ItemType[]>([])
  const [tableData, setTableData] = useState<CommonsUser[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<CommonsUser[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<CommonsUsersRequestData>()
  const [filters, setFilters] = useState<CommonsUsersFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const activeTabRef = useRef(activeTab)
  const user = useSelector(getUser)
  const allowedRoles = useSelector(getAllowedRolesList)
  const dispatch = useDispatch()
  const { push } = useRouter()
  const debouncedSearch = useDebounce(searchValue, 500)

  const isCCS = useMemo(
    () => Boolean(user.role === ROLES.COMMONS_CONTRACT_STEWARD),
    [user.role]
  )

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (activeTab) {
      timeoutRef.current = setTimeout(
        () => getTableData({ ...sortParams, ...filters }),
        firstLoad || activeTab !== activeTabRef.current ? 0 : 500
      )
    }
  }, [debouncedSearch, activeTab])

  useEffect(() => {
    if (!!allowedRoles.length) {
      const roles =
        allowedRoles.find((i) => i.tab_name === 'commons')?.roles || []
      const selectedRole = !!roles.length ? roles[0].value : ''
      setRolesList(
        roles.map((i) => ({
          key: i.value,
          label: i.label
        }))
      )
      setActiveTab(selectedRole)
    }
  }, [allowedRoles.length])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: CommonsUsersRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: CommonsUsersParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getCommonsUsers(activeTab, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            name: i.first_name,
            phone_number: fullFormatPhoneNumber(i.phone_number),
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const createUser = () =>
    push(`${routes.createUserProfile}/new`, {
      state: { redirectTab: MY_COMMUNITY_TABS[2].key }
    })

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: CommonsUsersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }

  return (
    <div className="commons-users-list mt-16">
      <div className="commons-users-list__header row align-center space-between">
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          Commons users
        </Typography.Headline6>
        <div className="commons-users-list__actions row align-center">
          {!!selectedRowKeys.length ? (
            <Typography.Body1
              className="table-wrapper__select-title"
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
            >
              Selected {selectedRowKeys.length} of {totalNumber}
            </Typography.Body1>
          ) : (
            <Input
              className="commons-users-list__search allow-clear"
              propsInput={{
                allowClear: { clearIcon: <Close16 /> },
                placeholder: PLACEHOLDERS.SEARCH_RFP_USERS,
                prefix: <Search16 />,
                value: searchValue,
                onChange: (e) => setSearchValue(e.target.value)
              }}
            />
          )}
          <Can I={ACTIONS.CRUD} a={SUBJECTS.COMMONS_USERS}>
            {!selectedRowKeys.length && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Plus16 />}
                onClick={createUser}
                upperCase
              >
                {BTN_TXT.CREATE_USER}
              </Button>
            )}
            {!!selectedRows.length &&
              selectedRows.filter((i) => i.status !== USER_STATUSES.ARCHIVED)
                .length === selectedRows.length && (
                <UserArchivePopup<CommonsUser>
                  selected={selectedRows}
                  callback={() => {
                    setSelectedRowKeys([])
                    setSelectedRows([])
                    getTableData({ ...sortParams, ...filters })
                  }}
                  isTable
                />
              )}
          </Can>
        </div>
      </div>
      <Menu
        mode="horizontal"
        className="commons-users-list__users-tabs mt-12"
        onSelect={(e) => setActiveTab(e.key)}
        selectedKeys={[activeTab]}
        defaultSelectedKeys={[activeTab]}
        items={rolesList}
      />
      {!firstLoad && (
        <Table<CommonsUser>
          dataSource={tableData}
          className="community-users-table"
          columns={columns({
            applyFilters,
            filters,
            onStartChat
          })}
          emptyText="No users found"
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          onChangePage={handleChangePageSize}
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<CommonsUsersFilters>(
            debouncedSearch,
            filters
          )}
          createButton={
            ability.can(ACTIONS.CRUD, SUBJECTS.COMMONS_USERS)
              ? {
                  buttonText: BTN_TXT.CREATE_USER,
                  availability: true,
                  action: createUser
                }
              : undefined
          }
          rowSelection={
            ability.can(ACTIONS.CRUD, SUBJECTS.COMMONS_USERS)
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow,
                  getCheckboxProps: (record) => ({
                    disabled: record.uuid === user.uuid || isCCS
                    // && !AVAILABLE_FOR_CCS.find((i) => i === record?.role))
                  })
                }
              : undefined
          }
        />
      )}
    </div>
  )
}
